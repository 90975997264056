import { graphql } from "gatsby"
import { Image } from "../components/Global/Gui/Image";
import React from "react"
import { SiteMetadata, Carousel } from "../components/Shared";
import { Button } from "../components/Global/Gui/Buttons";

export default props => {
  const { product } = props.data
  const { description, gallery, name, summary, url, thumbnail } = product
  return (
    <div>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
              {gallery && gallery.length === 1 && (
                <Image image={gallery[0].localFile.childImageSharp.gatsbyImageData} />
              )}
              {gallery && gallery.length > 1 && <Carousel images={gallery} />}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-800 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-400 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {url && (
                <div className="mt-8">
                  <Button href={url}><span>Start Designing</span>
                  <svg class="hi-solid hi-adjustments inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"/></svg>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`query ProductQuery($slug: String!) {
  product: contentfulShopifyItems(slug: { eq: $slug }) {
    id
    name
    url
    summary
    description {
      description
    }
    gallery {
      id
      title
      localFile {
        childImageSharp {
          gatsbyImageData(width: 960, quality: 85, layout: CONSTRAINED)
        }
      }
    }
    thumbnail {
      localFile {
        publicURL
      }
    }
  }
}
`